<template>
    <div class="moreDiv">
        <!-- <myAccount></myAccount> -->
        <div class="overbox">
            <div class="account-section">
                <ul class="account-overview-section-container">
                    <li class="account-overview-section">
                        <div class="overview-more-info">
                            <div class="account-section-title">My Profile</div>
                            <ul class="userInfo">
                                <li><span>{{
                                        this.$store.getters.userInfo.firstName
                                    }}</span>.<span>{{ this.$store.getters.userInfo.lastName }}</span></li>
                                <li>{{ this.$store.getters.userInfo.email }}</li>
                                <!--            <li>16/09/1998</li>-->
                            </ul>
                            <!--          <div class="newsletter-info">Newsletter：Yes</div>-->
                            <router-link :to="{path:'/profile',query:{active:1}}">Edit your details</router-link>
                        </div>
                    </li>
                    <li class="account-overview-section">
                        <div class="overview-more-info">
                            <div class="account-section-title">My Orders</div>
                            <div class="userOrders userOrders">
                                <div class="OrderCountZero">You have not previously ordered as a registered customer.
                                </div>
                                <router-link :to="{path:'/orders',query:{active:2}}">View all your orders</router-link>
                            </div>
                        </div>
                    </li>
                    <li class="account-overview-section">
                        <div class="overview-more-info">
                            <div class="account-section-title">My Addresses</div>
                            <div class="userOrders userOrders">
                                <div class="OrderCountZero">Save all your delivery details to have them at hand when you
                                    order.
                                </div>
                                <router-link :to="{path:'/myAddress',query:{active:3}}">View all your addresses
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <li class="account-overview-section" @click="logout">
                        <div>
                            <router-link :to="{path:'/login'}" class="routerLogin">Log out</router-link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// import myAccount from "@/views/myAccount";

export default {
    name: "overView",
    components: {
        // myAccount
    },
    data() {
        return {};
    },
    mounted() {
    },
    methods: {
        logout() {
            this.$parent.logout();
        }
    }
};
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

.overbox {
    .account-overview-section-container {
        margin-top: 0;

        .account-overview-section {
            border-top: 1px solid #d8d7d5;
            margin: 0 0 1rem;
            padding-top: 1rem;

            .overview-more-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;

                .account-section-title {
                    margin-bottom: 1rem;
                }

                .userOrders {
                    margin-bottom: 1rem;
                    padding-right: 6.25rem;
                }

                .newsletter-info {
                    margin-bottom: 1rem;
                    width: 100%;
                }

                .userInfo {
                    line-height: 1.1875rem;
                }

                a {
                    align-self: flex-start;
                    line-height: normal;
                    border-bottom-color: currentColor;
                    position: absolute;
                    right: 0;
                    top: 0;
                }

                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .overbox {
        .account-section, .accountUserForm {
            margin: 0 auto;
            max-width: 100%;
            width: 25rem;
        }
    }
    .account-overview-section-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media (min-width: 1280px) {
    .overbox {
        .account-section {
            width: 51.875rem;
            margin: 0 auto;

            .account-overview-section {
                width: 23.4375rem;
            }

            .account-overview-section:nth-child(3) {
                border-bottom: 1px solid #d8d7d5;
                padding-bottom: 1rem;
            }
        }
    }
}

@media (max-width: 1280px) {

    .moreDiv {
        margin-top: 3.125rem;
        min-height: 75%;
    }
    .overview-more-info {
        padding: 0 1.1875rem;

        a {
            margin-right: 1.1875rem;
            border-bottom: 1px solid currentColor;
        }
    }
    .routerLogin {
        margin-left: 1.1875rem;
    }
}

</style>
